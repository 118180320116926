<div class="fixed-bottom-padding">
  <!-- footer -->
  <footer class="section-footer bg-white">
    <section class="footer-main border-top pt-5 pb-4">
      <div class="container">
        <div class="row">
          <aside class="col-md">
            <h6 class="title">Links úteis</h6>
            <ul class="list-unstyled list-padding">
              <!-- <li>
                <a [routerLink]="['/produtos']" class="text-dark">
                  Lista de produtos
                </a>
              </li> -->
              <!-- <li>
                <a
                  [routerLink]="['/produtos']"
                  [queryParams]="{ oferta: 'true' }"
                  class="text-dark"
                >
                  Ofertas Imperdíveis
                </a>
              </li> -->
              <!-- <li>
                <a [routerLink]="['/promos']" class="text-dark">Campanhas</a>
              </li> -->
              <li>
                <a [routerLink]="['/nossas-lojas']" class="text-dark"
                  >Nossas Lojas</a
                >
              </li>
              <li>
                <a
                  href="https://www.facebook.com/exallacosmeticos/"
                  target="_blank"
                  class="text-dark"
                  >Sou Exalla</a
                >
              </li>
            </ul>
          </aside>
          <aside class="col-md">
            <h6 class="title">Confira o seu Pedido</h6>
            <ul class="list-unstyled list-padding">
              <li>
                <a [routerLink]="['/carrinho']" class="text-dark">Carrinho</a>
              </li>
              <li>
                <a [routerLink]="['/meus-pedidos']" class="text-dark"
                  >Acompanhe</a
                >
              </li>
            </ul>
          </aside>
          <aside class="col-md">
            <h6 class="title">A Exalla</h6>
            <ul class="list-unstyled list-padding">
              <div class="text-md-right">
                <div class="rede-social uk-flex">
                  <a
                    href="https://www.facebook.com/exallacosmeticos/"
                    class="icone-rede-social btn btn-icon btn-light"
                    target="_blank"
                  >
                    <i class="icofont-facebook"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/exallacosmeticos/"
                    class="descricao-rede-social"
                    target="_blank"
                  >
                    Facebook.com
                  </a>
                </div>
                <div class="rede-social uk-flex">
                  <a
                    href="https://twitter.com/exallabeleza"
                    class="icone-rede-social btn btn-icon btn-light"
                    target="_blank"
                  >
                    <i class="icofont-twitter"></i>
                  </a>
                  <a
                    href="https://twitter.com/exallabeleza"
                    class="descricao-rede-social"
                    target="_blank"
                  >
                    Twitter.com
                  </a>
                </div>
                <div class="rede-social uk-flex">
                  <a
                    href="https://www.instagram.com/exallacosmeticos/"
                    class="icone-rede-social btn btn-icon btn-light"
                    target="_blank"
                  >
                    <i class="icofont-instagram"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/exallacosmeticos/"
                    class="descricao-rede-social"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </div>
                <div class="rede-social uk-flex">
                  <a
                    href="https://dagdistribuidor.com.br/trabalhe-conosco/"
                    class="icone-rede-social btn btn-icon btn-light"
                    target="_blank"
                  >
                    <i class="icofont-users"></i>
                  </a>
                  <a
                    href="https://dagdistribuidor.com.br/trabalhe-conosco/"
                    class="descricao-rede-social"
                    target="_blank"
                  >
                    Trabalhe Conosco
                  </a>
                </div>
              </div>
            </ul>
          </aside>
        </div>
      </div>
    </section>
    <section class="footer-bottom border-top py-4">
      <div class="container uk-text-center">
        <div class="row">
          <div class="">
            <span class="uk-text-bold">
              © {{ year }} Exalla Cosméticos. Todos os direitos reservados.
            </span>
          </div>
        </div>
      </div>
    </section>
  </footer>
</div>
