<div>
  <app-head></app-head>
</div>
<div id="main-container" [ngClass]="style ? 'uk-margin-auto' : ''">
  <link
    rel="stylesheet"
    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
  />
  <router-outlet></router-outlet>
</div>
<div class="mg-b">
  <app-footer></app-footer>
</div>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-whatsapp-float-button></app-whatsapp-float-button>
