import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { FavoritesService } from './shared/services/favorites.service';
import { CarrinhoService } from './shared/services/carrinho.service';
import { iCartDefaultResponse } from './views/cart/interfaces/cart.interface';
import { ActivatedRoute, NavigationEnd, Router, Routes } from '@angular/router';
import { ProdutoService } from './service/produto.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { SocialLoginService } from './shared/services/social-login.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'exalla';
  style: boolean = false;

  constructor(
    private carrinhoService: CarrinhoService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private produtoService: ProdutoService,
    private gService: AnalyticsService,
    private socialLogin: SocialLoginService
  ) {
    this.titleService.setTitle(`Exalla Cosméticos`);
  }

  ngOnInit() {
    // this.router.events.subscribe(event => {
    // if (event instanceof NavigationEnd) {
    //   let pageTitle = '';
    //   if (event.url === '/') {
    //     pageTitle = `HOME - EXALLA COSMÉTICOS`
    //   } else {
    //     pageTitle = `${event.url.split('?')[0].toUpperCase()} - EXALLA`;
    //   }
    //   TODO - Criar e chamar serviço do analytics
    //   this.pageView(event.url, pageTitle);
    //   this.firebaseService.setFbScreenName(event.url, pageTitle);
    // }
    // });

    // this.route.queryParams.subscribe((params) => {
    //   if (Object.values(params).length > 0) {
    //     if (params['lista']) {
    //       this.produtoService.getListaPersonalizada(params['lista']).subscribe({
    //         next: (response: any) => {
    //           if (response.status === true) {
    //             this.produtoService.produtosPersonalizados.next(
    //               response.listaInfo.produtos
    //             );
    //             this.router.navigateByUrl(
    //               '/produtos?lista=' +
    //                 params['lista'] +
    //                 '&acao=' +
    //                 response.listaInfo.parametro
    //             );
    //           }
    //         },
    //       });
    //     }
    //   }
    // });
    if (localStorage.getItem('@access_token') !== null) {
      this.socialLogin.getAccount().subscribe({
        next: (response) => {
          this.socialLogin.accountData.next(response);
          localStorage.setItem('cod_carrinho', response.cod_carrinho);
          if (response.cod_carrinho != null) {
            this.carrinhoService.returnCarrinho().subscribe({
              next: (response) => {
                this.carrinhoService.carrinhoSubject.next(response.data);
              },
            });
          }
        },
        error: (err) => {
          this.socialLogin.logOut();
        },
      });
    }
    if (localStorage.getItem('cod_carrinho') != null) {
      this.carrinhoService.returnCarrinho().subscribe({
        next: (response) => {
          this.carrinhoService.carrinhoSubject.next(response.data);
        },
      });
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.toggleStyle(event.url);
      }
    });
  }

  toggleStyle(r: string) {
    if (r === '/') {
      this.style = false;
    } else {
      this.style = true;
    }
  }
}
