import { PagamentoService } from './../../services/pagamento.service';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IAccountAdress } from 'src/app/model/account.model';
import { CepService } from '../../services/cep.service';
import { EnderecoService } from '../../services/endereco.service';
import { Pagamento } from 'src/app/model/pagamento';
import { SocialLoginService } from '../../services/social-login.service';
import { iCard } from 'src/app/views/add-cartao/interfaces/cartao.interface';
import { CartoesService } from 'src/app/views/add-cartao/services/cartoes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import flatpickr from 'flatpickr';

@Component({
  selector: 'app-cadastro-para-pagamento',
  templateUrl: './cadastro-para-pagamento.component.html',
  styleUrls: ['./cadastro-para-pagamento.component.scss'],
})
export class CadastroParaPagamentoComponent implements OnInit, AfterViewInit {
  @Input() changeEdit!: IAccountAdress;
  @Input() vendaAvulsa!: boolean;
  @Input() cartoes!: iCard[];

  @Output() salveForm: EventEmitter<any> = new EventEmitter();
  @Output() editForm: EventEmitter<any> = new EventEmitter();
  @Output() request: EventEmitter<any> = new EventEmitter();

  dataLimite: string = new Date().toISOString().split('T')[0];

  cep?: any;

  teste: boolean = true;

  loading: boolean = false;

  formCadastro!: FormGroup;
  bandeiraCartao!: string;
  mesmoCobranca: boolean = false;
  address: IAccountAdress[] = [];
  enderecoCobranca!: IAccountAdress;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private cepService: CepService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public enderecoService: EnderecoService,
    public pagamentoService: PagamentoService,
    private socialLoginService: SocialLoginService,
    private cartoesService: CartoesService
  ) {}

  ngOnInit(): void {
    document.addEventListener('DOMContentLoaded', function () {
      flatpickr('#data_nascimento', {
        dateFormat: 'd/m/Y',
      });
    });

    this.enderecoService.addressSubject$.subscribe({
      next: (value) => {
        if (value) {
          this.address = value;
          this.enderecoCobranca = value.filter(
            (p: any) => p.padrao === true
          )[0];
          this.setEnderecoPadrao();
        }
      },
    });

    this.formCadastro = new FormGroup({
      cpf: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      data_nascimento: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      // genero: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      district: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      address_number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      credit_card: new FormControl('', [Validators.required]),
      validade: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      // cvv: new FormControl('', [Validators.required]),
      salvar: new FormControl(true),
      salvarCvv: new FormControl(true),
      name: new FormControl('', [Validators.required]),
      mesmoCobranca: new FormControl(this.teste),
      enderecoCobranca: new FormControl(''),
    });

    this.cartoesService.getEnderecos().subscribe({
      next: (value: any) => {
        this.enderecoService.addressSubject$.next(value.result);
        this.address = value.result;
        if (this.formCadastro.controls['mesmoCobranca'].value === true) {
          this.enderecoCobranca = value.result.filter(
            (p: any) => p.padrao === true
          )[0];
          this.formCadastro.patchValue({
            enderecoCobranca: this.enderecoCobranca.cod_endereco,
          });
        }
        if (!value || (value && value.result.length === 0)) {
          this.formCadastro.patchValue({
            mesmoCobranca: false,
          });
        }
      },
    });
    this.addressEx();
    this.spinner.hide('cadastroCartao');
  }

  checkMesmoCobranca() {
    if (this.formCadastro.controls['mesmoCobranca'].value === true) {
      this.enderecoCobranca = this.address.filter(
        (p: any) => p.padrao === true
      )[0];
      this.formCadastro.patchValue({
        enderecoCobranca: this.enderecoCobranca.cod_endereco,
        zip: this.enderecoCobranca.cep,
        address: this.enderecoCobranca.rua,
        address_number: this.enderecoCobranca.numero,
        district: this.enderecoCobranca.bairro,
        state: this.enderecoCobranca.uf,
        city: this.enderecoCobranca.cidade,
        complement: this.enderecoCobranca.complemento,
        data_nascimento: this.formCadastro.controls['data_nascimento'].value,
      });
    } else {
      this.formCadastro.patchValue({
        enderecoCobranca: '',
        zip: '',
        address: '',
        address_number: '',
        district: '',
        state: '',
        city: '',
        complement: '',
      });
    }
  }

  setEnderecoPadrao() {
    if (
      this.formCadastro.controls['mesmoCobranca'].value === true &&
      this.address &&
      this.enderecoCobranca
    ) {
      this.formCadastro.patchValue({
        zip: this.enderecoCobranca.cep,
        address: this.enderecoCobranca.rua,
        address_number: this.enderecoCobranca.numero,
        district: this.enderecoCobranca.bairro,
        state: this.enderecoCobranca.uf,
        city: this.enderecoCobranca.cidade,
        complement: this.enderecoCobranca.complemento,
        data_nascimento: this.formCadastro.controls['data_nascimento'].value,
      });
    }
  }

  ngAfterViewInit(): void {
    this.setEnderecoPadrao();
    this.addressEx();
  }

  getCardFlag(cardNumber: any): string | false {
    if (cardNumber === null) return false;

    let cardFlags: { [key: string]: RegExp };

    cardNumber = cardNumber.target.value.replace(/[^0-9]+/g, '');

    cardFlags = {
      Amex: /^3[47][0-9]{13}$/,
      Aura: /^((?!504175))^((?!5067))(^50[0-9])/,
      BaneseCard: /^636117/,
      Cabal: /(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)/,
      Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
      FortBrasil: /^628167/,
      GrandCard: /^605032/,
      Hipercard: /^606282|^3841(?:[0|4|6]{1})0/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}/,
      Mastercard:
        /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
      PersonalCard: /^636085/,
      Sorocred: /^627892|^636414/,
      Valecard: /^606444|^606458|^606482/,
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    };

    for (let flag in cardFlags) {
      if (cardFlags[flag].test(cardNumber)) {
        this.bandeiraCartao = flag;
        return flag;
      }
    }

    return false;
  }

  getCobranca() {
    this.enderecoCobranca = this.address.filter(
      (p) =>
        p.cod_endereco == this.formCadastro.controls['enderecoCobranca'].value
    )[0];
  }

  consultaCep() {
    let valor = this.formCadastro.controls['zip'].value;

    this.cepService.buscarCep(valor).subscribe({
      next: (dados: any) => {
        this.cep = dados;
        this.populaForm(dados);
      },
      error(err) {},
    });
  }
  populaForm(dados: any) {
    if (dados.valid) {
      this.formCadastro.patchValue({
        zip: dados.cep,
        address: dados.logradouro,
        district: dados.bairro,
        city: dados.localidade,
        state: dados.uf,
        complement: dados.complemento,
      });
    }
  }

  enviarCadastro() {
    this.spinner.show('cadastroCartao');
    let {
      cpf,
      phone,
      data_nascimento,
      email,
      zip,
      address,
      district,
      state,
      city,
      address_number,
      complement,
      credit_card,
      validade,
      salvar,
      salvarCvv,
      name,
    } = this.formCadastro.value;

    let date = validade.split('');
    let cep = zip.split('');
    if (
      this.formCadastro.controls['mesmoCobranca'].value == true &&
      this.address.length !== 0
    ) {
      let userEmail = this.socialLoginService.accountData.value.email;
      userEmail = this.socialLoginService.accountData.value.email;
      let request: Pagamento = {
        paymentType: 1,
        parcel: 1,
        cod_cart: localStorage.getItem('cod_carrinho') ?? '',
        // salvarCvv: true,
        // salvar: this.vendaAvulsa == true && !this.socialLogin.isLogged ? false : true,
        cardInfo: {
          credit_card: credit_card,
          expiration_month: date[0] + date[1],
          expiration_year: date[2] + date[3] + date[4] + date[5],
          scheme: this.bandeiraCartao,
          billing: {
            name: name,
            cpf: cpf,
            // genero: genero,
            address: this.enderecoCobranca.rua,
            complement: this.enderecoCobranca.complemento,
            address_number: this.enderecoCobranca.numero,
            district: this.enderecoCobranca.bairro,
            city: this.enderecoCobranca.cidade,
            state: this.enderecoCobranca.uf,
            zip: this.enderecoCobranca.cep.replace('-', ''),
            phone: this.enderecoCobranca.phone,
            email: email,
            birth_date: this.formCadastro.value.data_nascimento,
          },
        },
      };
      this.request.emit(request);
      this.formCadastro.reset();
    } else {
      let request: Pagamento = {
        paymentType: 1,
        parcel: 1,
        cod_cart: localStorage.getItem('cod_carrinho') ?? '',
        // salvarCvv: true,
        // salvar: this.vendaAvulsa == true && !this.socialLogin.isLogged ? false : true,
        cardInfo: {
          credit_card: credit_card,
          expiration_month: date[0] + date[1],
          expiration_year: date[2] + date[3] + date[4] + date[5],
          scheme: this.bandeiraCartao,
          billing: {
            // genero: genero,
            name: name,
            address: address,
            complement: complement,
            address_number: address_number,
            district: district,
            city: city,
            state: state,
            cpf: cpf,
            zip:
              cep[0] +
              cep[1] +
              cep[2] +
              cep[3] +
              cep[4] +
              cep[6] +
              cep[7] +
              cep[8],
            phone: phone,
            email: email,
            birth_date: this.formCadastro.value.data_nascimento,
            // cvv: cvv,
          },
        },
      };
      this.request.emit(request);

      this.formCadastro.reset();
    }
  }

  checkDisabled() {
    if (
      (this.formCadastro.valid && this.address.length == 0) ||
      this.formCadastro.controls['mesmoCobranca'].value == false
    ) {
      return false;
    }
    if (
      this.formCadastro.controls['mesmoCobranca'].value == true &&
      this.formCadastro.controls['enderecoCobranca'].value !== ''
    ) {
      return false;
    }
    return true;
  }

  addressEx() {
    if (this.address.length == 0) {
      this.teste = false;
    } else {
      this.teste = true;
    }
  }
}
