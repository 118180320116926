<form class="formulario" [formGroup]="formCadastro">
  <div class="osahan-card-body pt-3">
    <div
      style="
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: #ff6060;
        color: #fff;
        font-weight: 600;
      "
    >
      O uso de um cartão de crédito de outra titularidade pode aumentar o tempo
      de confirmação do pagamento ou cancelar o seu pedido devido a uma
      verificação adicional de segurança.
    </div>
    <hr />
    <!-- <h6 class="m-0">Dados do cartão</h6> -->
  </div>

  <div class="row form-group">
    <div class="col-12">
      <label class="form-label font-weight-bold small">
        Nome igual do Cartão
      </label>
      <input
        formControlName="name"
        id="name"
        placeholder="Nome igual do Cartão"
        type="text"
        class="form-control"
      />
    </div>
  </div>
  <div class="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m">
    <div>
      <label for="number">CPF</label>
      <input
        formControlName="cpf"
        placeholder="CPF"
        type="text"
        class="form-control"
        id="number"
        mask="000.000.000-00"
      />
      <small
        class="alerta"
        *ngIf="
          this.formCadastro.controls['cpf'].value &&
          this.formCadastro.controls['cpf'].errors
        "
        >Insira CPF correto</small
      >
    </div>
    <div>
      <label for="telefone">Telefone</label>
      <input
        formControlName="phone"
        placeholder="Telefone"
        type="text"
        class="form-control uk-width-1-3"
        id="telefone"
        mask="(00) 00000-0000 || (00) 0000-0000"
      />
    </div>
    <div>
      <label for="data">Data de nascimento</label>
      <input
        formControlName="data_nascimento"
        type="date"
        class="form-control uk-width-1-3"
        id="data"
        min="1899-01-01"
        [max]="dataLimite"
      />
    </div>
  </div>

  <div
    class="row uk-padding-top uk-margin-bottom form-group"
    style="margin: 20px 0 !important"
  >
    <div class="col-12">
      <label for="email">Email</label>
      <input
        formControlName="email"
        placeholder="Email"
        type="email"
        class="form-control"
        id="email"
        aria-describedby="emailHelp"
      />
      <small
        class="alerta"
        *ngIf="
          this.formCadastro.controls['email'].value &&
          this.formCadastro.controls['email'].status === 'INVALID'
        "
        >Insira um Email válido</small
      >
    </div>
    <!-- <div class="col-4">
      <select class="form-control" placeholder="Selecione o gênero do titular do cartão" formControlName="genero">
        <option value="M">Masculino</option>
        <option value="F">Feminino</option>
      </select>
      <label for="gênero">Gênero</label>
    </div> -->
  </div>
  <div class="row form-group align-items-center">
    <div class="col-2 uk-text-center">
      <i
        *ngIf="!bandeiraCartao"
        class="icofont-credit-card"
        style="font-size: 3rem; color: #000"
      ></i>
      <img
        *ngIf="bandeiraCartao"
        class="imagem-cartao"
        [src]="
          '../../../../assets/imagens/imagens-cartões/bandeira_' +
          bandeiraCartao.toLowerCase() +
          '.jpeg'
        "
        label
        class="form-label font-weight-bold small"
      />
    </div>
    <div class="col-6">
      <label class="form-label font-weight-bold small">Número do Cartão</label>
      <input
        formControlName="credit_card"
        id="cartao"
        (blur)="getCardFlag($event)"
        placeholder="Só Números"
        type="text"
        mask="0000 0000 0000 0000"
        class="busca-cartao"
      />
    </div>
    <div class="col-4">
      <label class="form-label font-weight-bold small">Validade</label
      ><input
        formControlName="validade"
        id="validade"
        placeholder="MM/AAAA"
        mask="99/9999"
        type="text"
        class="form-control"
      />
    </div>
  </div>

  <!-- <div class="row form-group">
    <div class="col-12">
      <label for="gênero">Gênero</label>
      <label
        ><input
          class="uk-radio"
          type="radio"
          value="M"
          name="genero"
          formControlName="genero"
          checked
        />
        Masculino</label
      >
      <label
        ><input
          class="uk-radio"
          type="radio"
          value="F"
          name="genero"
          formControlName="genero"
        />
        Feminino</label
      >
    </div>
  </div> -->

  <div class="row form-group align-items-center">
    <!-- <div class="col-6">
      <label class="form-label font-weight-bold small">CVV</label
      ><input
        placeholder="Número CVV"
        type="text"
        mask="999"
        formControlName="cvv"
        id="cvv"
        class="form-control"
      />
    </div> -->
    <div class="col-3" style="display: none">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="custom-checkbox1"
          formControlName="salvarCvv"
          class="custom-control-input"
        />
        <label
          title=""
          type="checkbox"
          for="custom-checkbox1"
          class="custom-control-label small pt-1"
        >
          Salvar CVV</label
        >
      </div>
    </div>
  </div>

  <div class="row form-group" style="display: none">
    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="custom-checkbox2"
          value="true"
          formControlName="salvar"
          class="custom-control-input"
        />
        <label
          title=""
          type="checkbox"
          for="custom-checkbox2"
          class="custom-control-label small pt-1"
          >Salve este cartão com segurança para próxima compra</label
        >
      </div>
    </div>
  </div>

  <ng-container *ngIf="address.length > 0">
    <div class="row form-group">
      <div class="col-12">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="custom-checkbox35"
            value="true"
            formControlName="mesmoCobranca"
            class="custom-control-input"
            (change)="checkMesmoCobranca()"
          />
          <label
            title=""
            type="checkbox"
            for="custom-checkbox35"
            class="custom-control-label small pt-1"
            >Usar o mesmo endereço de entrega para cobrança</label
          >
        </div>
      </div>
      <ng-container
        *ngIf="formCadastro.controls['mesmoCobranca'].value == true"
      >
        <div class="col-12">
          <select
            (change)="getCobranca()"
            formControlName="enderecoCobranca"
            class="form-control"
          >
            <option *ngFor="let item of address" [value]="item.cod_endereco">
              {{ item.rua }}, {{ item.numero }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div
    *ngIf="
      formCadastro.controls['mesmoCobranca'].value == false ||
      address.length == 0
    "
  >
    <div class="osahan-card-body pt-3">
      <h6 class="m-0">Dados de cobrança</h6>
      <hr />
    </div>

    <!-- <div class="form-group">
      <label for="gênero">Gênero</label>
      <label
        ><input
          class="uk-radio"
          type="radio"
          value="M"
          name="genero"
          formControlName="genero"
          checked
        />
        Masculino</label
      >
      <label
        ><input
          class="uk-radio"
          type="radio"
          value="F"
          name="genero"
          formControlName="genero"
        />
        Feminino</label
      >
    </div> -->

    <div class="row form-group">
      <div class="col-4">
        <label for="zip">CEP:</label><br />
        <input
          class="form-control"
          type="text"
          id="cep"
          formControlName="zip"
          (blur)="consultaCep()"
        />
        <small class="alerta" *ngIf="cep && !cep.valid">Cep inválido</small>
      </div>
      <div class="col-6">
        <label for="address">RUA</label><br />
        <input
          type="text"
          class="form-control"
          id="address"
          formControlName="address"
          placeholder="..."
        />
      </div>
      <div class="col-2">
        <label for="address_number">NÚMERO</label><br />
        <input
          type="text"
          class="form-control"
          id="address_number"
          formControlName="address_number"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-5">
        <label for="district">BAIRRO</label><br />
        <input
          type="text"
          class="form-control pesquisa-bairro"
          id="district"
          formControlName="district"
          placeholder="..."
        />
      </div>
      <div class="col-5">
        <label for="city">CIDADE</label><br />
        <input
          type="text"
          class="form-control pesquisa-cidade"
          id="city"
          formControlName="city"
          placeholder="..."
        />
      </div>
      <div class="col-2">
        <label for="state">UF</label><br />
        <input
          type="text"
          class="form-control pesquisa-uf"
          id="state"
          formControlName="state"
          placeholder="..."
        />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-12">
        <label for="complement">COMPLEMENTO</label><br />
        <input
          type="text"
          class="form-control pesquisa-complemento"
          id="complement"
          formControlName="complement"
        />
      </div>
    </div>
  </div>

  <div class="uk-width-1-1 uk-text-right">
    <button
      [disabled]="checkDisabled()"
      (click)="enviarCadastro()"
      class="btn btn-success btn-lg btn-block mt-3"
      [ngClass]="{ 'uk-disabled botao-disabilitado': formCadastro.invalid }"
      type="submit"
    >
      Salvar
    </button>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    name="cadastroCartao"
  >
    <p style="font-size: 20px; color: white">Carregando...</p>
  </ngx-spinner>
</form>
