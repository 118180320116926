import { CarrinhoService } from './../../services/carrinho.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SocialLoginService } from '../../services/social-login.service';
import { NavigationEnd, Router } from '@angular/router';
import UIkit from 'uikit';
import { ProductData } from 'src/app/model/carrinho';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss'],
})
export class HeadComponent implements OnInit, AfterViewInit {
  search: string = '';

  @ViewChild('dialogInfo') dialogInfo!: ElementRef;

  isHidden = false;
  lastScrollTop = 0;

  address = [
    {
      nome: 'Loja Exalla Bezerra de Menezes',
    },
    {
      nome: 'Loja Exalla Augusto dos Anjos',
    },
    {
      nome: 'Loja Exalla Francisco',
    },
    {
      nome: 'Loja Exalla Centro 1',
    },
    {
      nome: 'Loja Exalla Messejana',
    },
    {
      nome: 'Loja Exalla Shopping Aledota',
    },
    {
      nome: 'Loja Exalla Cambeba',
    },
    {
      nome: 'Loja Exalla Shopping Eusébio',
    },
    {
      nome: 'Loja Exalla Solares Shopping',
    },
    {
      nome: 'Loja Exalla Maracanaú',
    },
  ];

  url: string = '/';

  @ViewChild('fixed') fixed!: ElementRef;
  @ViewChild('fixedMobile') fixedMobile!: ElementRef;
  @ViewChild('offCanvas') offCanvas!: ElementRef;
  @ViewChild('preview') previewElement!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('searchInputMobile') searchInputMobile!: ElementRef;
  menu!: ElementRef;
  qtdCarrinho: number = 0;
  nomeCliente?: string;

  sticky!: number;
  leavePrompted = false;
  fixedNum = false;
  products: [] = [];
  leavePopupImage?: string;

  @HostListener('window:storage')
  onStorageChange() {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const number = $event.target.scrollingElement.scrollTop;

    if (number > 40) {
      this.fixedNum = true;
      this.fixed.nativeElement.classList.add('fixed-header');
      this.fixedMobile.nativeElement.classList.add('fixed-header');
      this.searchInputMobile.nativeElement.classList.add('invisible');
      return;
    }

    if (this.fixed && number < 10) {
      this.fixedNum = false;
      this.fixed.nativeElement.classList.remove('fixed-header');
      this.fixedMobile.nativeElement.classList.remove('fixed-header');
      this.url.includes('carrinho')
        ? this.searchInputMobile.nativeElement.classList.add('invisible')
        : this.searchInputMobile.nativeElement.classList.remove('invisible');
    }
  }

  @HostListener('document:mouseleave', ['$event'])
  // document.addEventListener('mouseleave', this.onWindowLeave);
  onWindowLeave() {
    if (this.leavePrompted || !this.leavePopupImage) {
      return;
    }

    this.leavePrompted = true;
    UIkit.modal('#leave-modal').show();
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event: Event): void {
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  //   if (scrollTop > this.lastScrollTop) {
  //     // Rolando para baixo
  //     this.isHidden = true;
  //   } else {
  //     // Rolando para cima
  //     this.isHidden = false;
  //   }

  //   this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  // }

  // @HostListener('keyup:enter', ['$event'])
  // document.addEventListener('mouseleave', this.onWindowLeave);
  // clickSearch(event: any) {
  //   if (event.code === 'Escape' || event.code === 'Enter') {
  //     if (event.code === 'Enter') {
  //       this.buscar(false);
  //     }
  //     this.search = '';
  //   }
  // }

  showPesquisa: boolean = false;

  constructor(
    public socialLoginService: SocialLoginService,
    private router: Router,
    public carrinhoService: CarrinhoService,
    private modalService: ModalService
  ) {
    this.menu = this.offCanvas;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.url = event.urlAfterRedirects;
        this.search = '';
        this.url.includes('carrinho')
          ? this.searchInputMobile.nativeElement.classList.add('invisible')
          : this.searchInputMobile.nativeElement.classList.remove('invisible');
      });
    this.carrinhoService.carrinhoSubject.subscribe((carrinho) => {
      if (carrinho) {
        this.products = carrinho.products;
        this.qtdCarrinho = carrinho.products
          ? carrinho.products.length
          : carrinho.length;
        if (carrinho.length > 0) {
          this.products = carrinho.products;
          this.qtdCarrinho = carrinho.products
            ? carrinho.products.length
            : carrinho.length;
        }
      } else {
        this.products = [];
        this.qtdCarrinho = 0;
      }
    });

    this.socialLoginService.accountData.subscribe((value) => {
      if (value) {
        this.nomeCliente = value.nome_social
          ? value.nome_social
          : value.name.split(' ')[0];
      } else {
        this.nomeCliente = undefined;
        this.qtdCarrinho = 0;
        this.products = [];
      }
    });
  }

  ngAfterViewInit() {
    // this.http.get(`${environment.api}/home/popup-saida`).subscribe((res: any) => {
    //   this.leavePopupImage = res.imagem;
    // });
  }

  setSticky() {
    if (window.pageYOffset > this.sticky) {
      this.fixed.nativeElement.classList.add('sticky');
    } else {
      this.fixed.nativeElement.classList.remove('sticky');
    }
  }

  logOut() {
    this.socialLoginService.logOut();
  }

  limpar() {
    this.search = '';
  }

  pesquisaMobile() {
    if (this.showPesquisa == false) {
      this.showPesquisa = true;
    } else {
      this.showPesquisa = false;
    }
  }

  buscar(event?: any) {
    if (this.search && this.search.length > 0) {
      this.router.navigate(['/produtos'], {
        queryParams: {
          pesquisa: this.search,
        },
      });
      this.limpar();
    }
  }

  preview() {
    if (
      this.search.length > 3 &&
      this.searchInput.nativeElement === document.activeElement
    ) {
      return true;
    }
    return false;
  }

  showInfo() {
    UIkit.modal(this.dialogInfo.nativeElement).show();

    // setTimeout(() => {
    //   UIkit.modal(this.dialogInfo.nativeElement).hide();
    // }, 5000);
  }
}
